<template>
  <BlokkliProvider
    v-slot="{ isEditing, isPreview, entity }"
    v-bind="blokkli"
    :entity="props"
  >
    <ContentHeader
      v-if="title"
      :title="entity?.title || title"
      :lead="entity?.lead || lead"
      :media="hideImage ? undefined : entity?.image || image"
      :easy-to-read-url="easyToReadUrl?.path"
    />

    <Container v-if="children && children.length" class="my-30 xl:my-50">
      <MenuList>
        <MenuListItem
          v-for="(child, key) in children"
          :key="key"
          v-bind="child"
        />
      </MenuList>
    </Container>

    <slot />

    <BlokkliField
      v-slot="{ items }"
      :list="paragraphs"
      name="field_paragraphs"
      :allowed-fragments="['zuzug', 'sound_of_religion']"
    >
      <TableOfContents :paragraph-items="items" :is-editing="isEditing" />
    </BlokkliField>

    <LayoutSection
      v-if="
        (paragraphsNextActions && paragraphsNextActions.length) ||
        isEditing ||
        isPreview
      "
      bg-blue
      padding
    >
      <BlokkliField
        :list="paragraphsNextActions"
        name="field_paragraphs_next_actions"
        class="grid grid-cols-1 md:grid-cols-3 md:gap-20 gap-40"
      />
    </LayoutSection>

    <BoxSection v-if="date || organizations?.length || contact">
      <BoxSectionItemOrganizations :organizations="organizations" />
      <BoxSectionItemUpdate v-if="date" :date="date" />
      <BoxSectionItemContact :contact="contact" />
    </BoxSection>

    <LayoutSection
      v-if="parent && parent.url?.path && topicLevel > 3"
      padding
      :title="
        $texts('topicPage.notWhatYouAreLookingFor', 'Nicht was Sie suchen?')
      "
    >
      <NuxtLink
        :to="parent.url.path"
        class="button is-super is-prev is-limited"
      >
        {{
          $texts('topicPage.backToParent', 'Zurück zu @label').replace(
            '@label',
            parent.label || '',
          )
        }}
      </NuxtLink>
    </LayoutSection>
  </BlokkliProvider>
</template>

<script lang="ts" setup>
import type { NodeTopicFragment } from '#graphql-operations'

const props = defineProps<{
  id?: NodeTopicFragment['id']
  uuid: NodeTopicFragment['uuid']
  title?: NodeTopicFragment['title']
  easyToReadUrl?: NodeTopicFragment['easyToReadUrl']
  lastUpdate?: NodeTopicFragment['lastUpdate']
  lead?: NodeTopicFragment['lead']
  children?: NodeTopicFragment['children']
  paragraphs?: NodeTopicFragment['paragraphs']
  paragraphsNextActions?: NodeTopicFragment['paragraphsNextActions']
  image?: NodeTopicFragment['image']
  organizations?: NodeTopicFragment['organizations']
  parent?: NodeTopicFragment['parent']
  contact?: NodeTopicFragment['contact']
  canEdit?: NodeTopicFragment['canEdit']

  // Non-fragment props.
  hideImage?: boolean
}>()

const { $texts } = useNuxtApp()

const blokkli = buildBlokkliProps(props, 'topic')

const breadcrumbLinks = useBreadcrumbLinks()

// This is not a computed property on purpose, because we don't want it to be
// reactive. If it were reactive and the user navigates away from this page,
// the value would update using the breadcrumb length of the next page.
const topicLevel = breadcrumbLinks.value.length - 2

const date = computed(() =>
  !!props.lastUpdate && topicLevel > 2 && props.lastUpdate.first
    ? props.lastUpdate.first
    : null,
)

useCDNHeaders((v) => v.addTags(['nuxt:page:topic']))
</script>
